import checkJs from "./modules/checkJs";
import date from "./modules/date";
import scroll from "./modules/scroll";

try {
  checkJs();
} catch (error) {
  console.log(error);
}

try {
  date();
} catch (error) {
  console.log(error);
}

try {
  scroll();
} catch (error) {
  console.log(error);
}
