const scroll = () => {

  const header = document.querySelector(".header");
  const nav = header.querySelector(".header__nav");
  const links = document.querySelectorAll(".link");
  const button = document.querySelector(".header__burger");

  button.addEventListener("click", () => {
    button.classList.toggle("open");
    nav.classList.toggle("header__nav_active");
  });


  // Реализация плавного скролла по id
  links.forEach((link) => {
    link.addEventListener("click", (event) => {
      const linkHref = link.getAttribute("href");
      const section = document.querySelector(linkHref);
      if (linkHref.indexOf('#') > -1) {
        event.preventDefault();
      }

      if (section) {
        // Обычный скролл
        section.scrollIntoView({
          block: "start",
          behavior: "smooth"
        })

        if (nav.classList.contains("header__nav_active")) {
          nav.classList.remove("header__nav_active");
          button.classList.remove("open");
        }
      }
    })
  })
}

export default scroll;
